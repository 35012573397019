const body = document.querySelector("body");
const html = document.querySelector("html");
const ua = UAParser();

// UA
var agent = window.navigator.userAgent.toLowerCase();
var ipad = agent.indexOf("ipad") > -1 || (agent.indexOf("macintosh") > -1 && "ontouchend" in document);
if (ua.device.type == undefined) {
    if (ipad == true) {
        html.classList.add("ua_tablet");
    } else {
        html.classList.add("ua_pc");
    }
} else {
    html.classList.add("ua_" + ua.device.type);
}

// ページトップ
const pagetopBtn = document.querySelector(".footer__pagetop__link");
pagetopBtn.addEventListener("click", function () {
    window.scrollTo({
        top: 0,
        behavior: "smooth",
    });
});

// ロード時カルーセルにクラス付与
window.addEventListener("load", function (e) {
    const primarySlider = document.querySelectorAll(".primary-slider");
    if (!primarySlider) return;
    primarySlider.forEach((element) => {
        element.classList.add("primary-slider--loaded");
    });
});

// アンカースクロール
const smoothScrollTrigger = document.querySelectorAll('a[href^="#"]');
for (let i = 0; i < smoothScrollTrigger.length; i++) {
    smoothScrollTrigger[i].addEventListener("click", function (e) {
        e.preventDefault();
        let href = e.currentTarget.getAttribute("href");
        let targetElement = document.getElementById(href.replace("#", ""));
        const rect = targetElement.getBoundingClientRect().top;
        const offset = window.pageYOffset;
        var margin = 60;
        if (window.innerWidth >= 1025) {
            margin = 100;
        }
        const target = rect + offset - margin;
        window.scrollTo({
            top: target,
            behavior: "smooth",
        });
    });
}
// ページをまたぐアンカーリンク
$("a[data-anchor]").on("click", function (event) {
    var data = $(event.currentTarget).data("anchor");
    var anchor = data.split("?");
    if (anchor[0] == location.pathname) {
        // ページが同じならスクロール
        var position = $("#" + anchor[1].split("=")[1]).offset().top;
        $("html, body").animate({ scrollTop: position - $(".header").outerHeight() }, 500, "swing");
        // URLに挿入する
        history.replaceState("", "", data);
    } else {
        // ページが異なるなら該当ページへ移動させる
        location.href = data;
    }
});
// アクセス時にanchorが存在したらスクロールさせる
if (document.URL.match(/anchor/)) {
    $(window).on("load", function (event) {
        var data = location.href;
        var anchor = data.split("?");
        var position = $("#" + anchor[1].split("=")[1]).offset().top;
        $("html, body").animate({ scrollTop: position - $(".header").outerHeight() }, 300, "swing");
    });
}


$(function () {
    // top mv
    if ($(".top-mv__image").length) {
        $(".top-mv__image").slick({
            fade: true,
            arrows: false,
            dots: false,
            autoplay: true,
            speed: 2000,
        });
    }
    // 記事スライダー
    if ($(".article-box-slider__list").length) {
        var articleSlick = $(".article-box-slider__list")
            .slick({
                dots: false,
                //centerMode: true,
                mobileFirst: true,
                infinite: false,
                responsive: [
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToScroll: 3,
                            slidesToShow: 3,
                            centerMode: false,
                        },
                    },
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToScroll: 1,
                            slidesToShow: 4,
                            centerMode: false,
                        },
                    },
                ],
            })
            .on("setPosition", function (event, slick) {
                articleSlick.find(".article-box").tile();
                $(".article-box-slider .slick-arrow").each(function (index) {
                    const slider = $(this).closest(".article-box-slider");
                    const imageHeight = slider.find(".article-box__image img").height();
                    $(".article-box-slider .slick-arrow").css({
                        top: imageHeight,
                    });
                });
            });
    }
    // header 子メニュー
    var headerHover1, headerHover2;
    const headerHoverDelay = 300;
    const headerHasChild = $(".header__menu__item--has-child");
    const headerHasChildLink = $(".header__menu__link");
    const headerHasChildActive = "is-active";
    if (window.innerWidth > 1024) {
        if ($("html").hasClass("ua_pc")) {
            // PC
            headerHasChild.on({
                mouseenter: function () {
                    var t = $(this);
                    headerHover1 = setTimeout(function () {
                        headerHasChildLink.removeClass(headerHasChildActive);
                        t.find(headerHasChildLink).addClass(headerHasChildActive);
                    }, headerHoverDelay);
                    clearTimeout(headerHover2);
                },
                mouseleave: function () {
                    var t = $(this);
                    headerHover2 = setTimeout(function () {
                        t.find(headerHasChildLink).removeClass(headerHasChildActive);
                    }, headerHoverDelay);
                    clearTimeout(headerHover1);
                },
            });
        } else {
            // タブレットとスマホ
            headerHasChild.find(headerHasChildLink).on("click", function () {
                event.preventDefault();
                if (!$(this).hasClass(headerHasChildActive)) {
                    headerHasChildLink.removeClass(headerHasChildActive);
                    $(this).addClass(headerHasChildActive);
                } else {
                    $(this).removeClass(headerHasChildActive);
                }
            });
        }
    }
    // インタビュー　スライダー
    if ($(".careers-interviews-detail-others__slider__content").length) {
        var interviewsSlickTarget = $(".careers-interviews-detail-others__slider");
        var interviewsSlick = $(".careers-interviews-detail-others__slider__content")
            .slick({
                mobileFirst: true,
                infinite: false,
                slidesToScroll: 1,
                slidesToShow: 1,
                responsive: [
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToScroll: 1,
                            slidesToShow: 2,
                        },
                    },
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToScroll: 1,
                            slidesToShow: 3,
                        },
                    },
                ],
            })
            .on("setPosition", function (event, slick) {
                interviewsSlickTarget.find(".careers-interviews-detail-others__panel__outline").tile();
                interviewsSlickTarget.find(".slick-arrow").each(function (index) {
                    const imageHeight = interviewsSlickTarget.find(".careers-interviews-detail-others__panel__image").height();
                    interviewsSlickTarget.find(".slick-arrow").css({
                        top: imageHeight,
                    });
                });
                const bgHeight = interviewsSlickTarget.find(".careers-interviews-detail-others__panel__image").height();
                const bgHeightSet = "calc(100% - " + bgHeight + "px)";
                $(".careers-interviews-detail-others__slider__bg").css({
                    height: bgHeightSet,
                });
            });
    }
    if ($(window).width() <= 768) {
        var interviewsSlickTarget02 = $(".primary-panel-list--slick");
        $(".primary-panel-list--slick")
            .not(".slick-initialized")
            .slick({
                infinite: false,
                slidesToScroll: 1,
                slidesToShow: 1,
            })
            .on("setPosition", function (event, slick) {
                interviewsSlickTarget02.find(".primary-panel__textarea").tile();
                interviewsSlickTarget02.find(".slick-arrow").each(function (index) {
                    const imageHeight = interviewsSlickTarget02.find(".primary-panel__image").height();
                    interviewsSlickTarget02.find(".slick-arrow").css({
                        top: imageHeight,
                    });
                });
                const bgHeight = interviewsSlickTarget.find(".primary-panel__image").height();
                const bgHeightSet = "calc(100% - " + bgHeight + "px)";
                $(".careers-interviews-detail-others__slider__bg").css({
                    height: bgHeightSet,
                });
            });
    } else {
        $(".primary-panel-list--slick" + ".slick-initialized").slick("unslick");
    }
    // 働く環境　スライダー
    if ($(".careers-office__gallery__slider__content").length) {
        var officeSlickTarget = $(".careers-office__gallery__slider");
        var officeSlick = $(".careers-office__gallery__slider__content")
            .slick({
                dots: true,
                appendDots: officeSlickTarget.find(".primary-slider__utility"),
                centerMode: true,
                variableWidth: true,
                mobileFirst: true,
            })
            .on("setPosition", function (event, slick) {
                officeSlickTarget.find(".slick-arrow").each(function (index) {
                    const imageHeight = officeSlickTarget.find(".careers-office__gallery__image").height();
                    officeSlickTarget.find(".slick-arrow").css({
                        top: imageHeight / 2,
                    });
                });
            });
    }
    // パネル スライダー
    if ($(".secondary-panel-list").length) {
        var divisionSlider = $(".secondary-panel-list");
        var divisionSliderItem = $(".secondary-panel");
        var divisionSliderSlick = divisionSlider
            .slick({
                infinite: false,
                slidesToScroll: 1,
                slidesToShow: 1,
                mobileFirst: true,
                responsive: [
                    {
                        breakpoint: 769,
                        settings: {
                            slidesToScroll: 3,
                            slidesToShow: 3,
                        },
                    },
                    {
                        breakpoint: 960,
                        settings: {
                            slidesToScroll: 4,
                            slidesToShow: 4,
                        },
                    },
                ],
            })
            .on("setPosition", function (event, slick) {
                divisionSlider.find(".secondary-panel").tile();
                divisionSlider.find(".slick-arrow").each(function (index) {
                    const imageHeight = $(this).closest(divisionSlider).find(".secondary-panel__image").height();
                    const imagePadding = parseInt($(this).closest(divisionSlider).find(".secondary-panel").css("padding-top"));
                    $(this).css({
                        top: imageHeight / 2 + imagePadding + "px",
                    });
                });
            });
        // PC5件未満、SP2件未満の場合はスライダー解除
        if (window.innerWidth > 600) {
            if (divisionSlider.find(divisionSliderItem).length >= 5) return;
            divisionSlider.addClass("is-no-slider").slick("unslick");
            divisionSlider.closest(".secondary-panel-content").addClass("is-no-slider");
        } else {
            if (divisionSlider.find(divisionSliderItem).length >= 2) return;
            divisionSlider.addClass("is-no-slider").slick("unslick");
            divisionSlider.closest(".secondary-panel-content").addClass("is-no-slider");
        }
    }
});

// tile
(function (e) {
    e.fn.tile = function (t) {
        var n,
            r,
            i,
            s,
            o,
            u,
            a = document.body.style,
            f = ["height"],
            l = this.length - 1;
        if (!t) t = this.length;
        u = a.removeProperty ? a.removeProperty : a.removeAttribute;
        return this.each(function () {
            u.apply(this.style, f);
        }).each(function (u) {
            s = u % t;
            if (s == 0) n = [];
            r = n[s] = e(this);
            o = r.css("box-sizing") == "border-box" ? r.outerHeight() : r.innerHeight();
            if (s == 0 || o > i) i = o;
            if (u == l || s == t - 1) {
                e.each(n, function () {
                    this.css("height", i);
                });
            }
        });
    };
})(jQuery);

// IE closest polyfill
if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
        var el = this;
        do {
            if (el.matches(s)) return el;
            el = el.parentElement || el.parentNode;
        } while (el !== null && el.nodeType === 1);
        return null;
    };
}

// topMV animation
window.addEventListener("DOMContentLoaded", () => {
    const animationHeader = document.querySelector(".header");
    const animationTopMv = document.querySelector(".top-mv");
    const animationTopMvNews = document.querySelector(".top-mv__news");
    const animationTopContent = document.querySelector(".top-content");
    if (animationTopMv) {
        animationTopMv.classList.add("animation");
        setTimeout(() => {
            animationHeader.classList.add("animation");
        }, 700);
        setTimeout(() => {
            animationTopContent.classList.add("animation");
        }, 1000);
        if (animationTopMvNews) {
            setTimeout(() => {
                animationTopMvNews.classList.add("animation");
            }, 1200);
        }
    }
});

// アコーディオン
const accordionTrigger = document.querySelectorAll(".accordion-box__head");
const accordionTriggerArray = Array.from(accordionTrigger);
accordionTriggerArray.forEach(function (target) {
    target.addEventListener("click", function () {
        const parent = target.closest(".accordion-box");
        parent.classList.toggle("is-active");
    });
});

// くるまざ会のリンク
const kurumaza = document.querySelector("#kurumaza");
if (kurumaza) {
    kurumaza.addEventListener("click", (elm) => {
        location.href = "/careers/office/?id=open4";
        return false;
    });
}

$(window).scroll(function () {
    var scroll = $(window).scrollTop();
    if ($(".footer").length) {
        var footerTop = $(".footer").offset().top;
    }
    var windowHeight = $(window).height();
    if (scroll > 150 && scroll < footerTop - windowHeight) {
        $(".floating-banner").fadeIn(200);
    } else {
        $(".floating-banner").fadeOut(200);
    }
});


function floatingBtn() {
    var scroll = $(window).scrollTop();
    if ($(".footer").length) {
        var footerTop = $(".footer").offset().top;
    }
    var windowHeight = $(window).height();
    if (scroll < footerTop - windowHeight) {
        $(".careers-mv__button").addClass("careers-mv__button--active");
    } else {
        $(".careers-mv__button").removeClass("careers-mv__button--active");
    }
}
// スクロール時floatingBtn実行
$(window).scroll(function () {
    floatingBtn();
});
// 画面ロード時にfloatingBtnを実行
$(window).on("load", function () {
    floatingBtn();
});


// メインビジュアルスライダー
const sliderList = document.querySelector('#slider__list');
const sliderTime = 5000;

if (sliderList !== null) {
    const sliderItems = Array.from(sliderList.querySelectorAll('.slider__item'));
    const topMvHeading = document.querySelector('#top-mv__heading');
    const sliderAnimation = document.querySelector('#slider-animation');
    const seconditem = document.querySelectorAll('.top-mv__second-item');

    // currentクラスを追加する関数
    const addCurrentClass = () => {
        let currentSliderItem = document.querySelector('.current-slider__item');
        currentSliderItem.classList.remove('current-slider__item');
        const nextItem = currentSliderItem.nextElementSibling || sliderItems[0];
        nextItem.classList.add('current-slider__item');
    }

    // z-indexを変更する関数 (z-index=2で一番前に表示される)
    const updateZIndex = () => {
        sliderItems.forEach(item => {
            item.style.zIndex = item.classList.contains('current-slider__item') ? 2 : 1;
        });
    }

    // タイトル背景色追加する関数
    const updateTitleBgColor = () => {
        sliderItems.forEach(item => {
            if (item.dataset.color === 'black') {
                topMvHeading.classList.toggle('top-mv__heading--black', item.classList.contains('current-slider__item'));
            }
        });
    }

    // 画像切り替えアニメーション関数
    const changeAnamation = () => {
        setTimeout(() => {
            sliderAnimation.classList.add('slider-animation');
            setTimeout(() => {
                sliderAnimation.classList.remove('slider-animation');
            }, 2000);
        }, 4000);
    }

    const sliderAnimationInterval = () => {
        addCurrentClass();
        updateZIndex();
        updateTitleBgColor();
        changeAnamation();
    };

    sliderAnimationInterval();
    setInterval(sliderAnimationInterval, sliderTime);
}